$(document).ready(function () {

  // input file

  $('#photo').change(function (event) {
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    reader.onload = function (event) {
      $('#file').html($("<img src='" + event.target.result + "' alt=''>"));
    };

    reader.readAsDataURL(selectedFile);
  });

  $('#photo-name').change(function (event) {
    var fileName = event.target.files[0].name;
    $(this).parents('.file-group').find('span.name').text(fileName);
  });

  // select option

  $('.choose>h5').click(function () {
    $(this).next('ul.option').toggleClass('active');
    $(this).toggleClass('active');

    // close options on window click
    $(window).click(function (e) {
      if (e.target.id !== 'selected') {
        $('ul.option').removeClass('active');
        $('.choose>h5').removeClass('active');
      }
    })
  });

  // option click

  $('.option>li>a').click(function (e) {
    e.preventDefault();
    $('.option>li>a').removeClass('active');
    $(this).addClass('active');
    $(this).parents('.option').prev('h5').children('span').text((this).text);
  });

  // modal

  $('.modal').click(function (e) {
    if ($(e.target).hasClass('modal') && $(this).hasClass('active')) {
      $('.modal').removeClass('active');
    };
  });

  $('.modal-open').click(function (e) {
    e.preventDefault();
    $('.modal').removeClass('active');
    $($(this).attr('href')).addClass('active');
  });

  $('.modal-close').click(function (e) {
    e.preventDefault();
    $('.modal').removeClass('active');
  });

  // tabs 

  $('.tab-menu-item').click(function (e) {
    e.preventDefault();
    $(this).parents('ul').find('.tab-menu-item').removeClass('active');
    $(this).addClass('active');
    $($(this).parents('.tabs')[0]).children('.tab-content').children('.tab-item').removeClass('active');
    $($(this).attr('href')).addClass('active');
  });

  // public 

  $('.public').click(function () {
    $(this).toggleClass('active');
    $('.post-group').toggleClass('active');
  });

  // steps

  $('.step-btn').click(function (e) {
    e.preventDefault();
    var elemId = $(this).attr('href');
    var step = $(this).attr('data-step');
    $('.' + step).removeClass('visible');
    $(elemId).addClass('visible');
  })

  // suggested

  $('.open-item').click(function () {
    $(this).toggleClass('active');
    $('.open-item>ul.item>li').toggleClass('active');
  });

  // toggle menu

  $('.toggle-menu').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('opened');
    $('.mobile-menu').toggleClass('opened');
  })

});